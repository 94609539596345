import BokehPlot from "./BokehPlot";
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from "./DataTable";
import {
    Modal,
    Typography,
    TextField,
    Box,
    CircularProgress,
    IconButton,
    MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from "./buttons/Button";


function Report({ id, name }) {
    const [plotData, setPlotData] = useState(null);
    const [Loadingerror, setLoadingError] = useState(null);
    const [ArchiveBarplot, setArchiveBarplot] = useState(null);
    const location = useLocation();
    const { institutionID, institutionName } = location.state || {};
    const sid = institutionID || id;
    const instname = institutionName || name;
    const [ArchiveTable, setArchiveTable] = useState(null);
    const [ChampionTable, setChampionTable] = useState(null);
    const [ChampionConsortiumTable, setChampionConsortiumTable] = useState(null);
    const [championTablesChecked, setChampionTablesChecked] = useState(false);
    const [Loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [message, setMessage] = useState('');
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [emailExists, setEmailExists] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [isInvitationSent, setIsInvitationSent] = useState(false);
    const [emailPreview, setEmailPreview] = useState('');
    const [isError, setIsError] = useState(false); // New state to track errors

    let apiUrl;
    const environment = process.env.REACT_APP_ENV;
    switch (environment) {
        case 'LOCAL':
            apiUrl = process.env.REACT_APP_API_Local_URL;
            break;
        case 'DEV':
            apiUrl = process.env.REACT_APP_API_URL;
            break;
        case 'PRD':
            apiUrl = process.env.REACT_APP_API_URL;
    }

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setMessage('');
        setEmail('');
        setGivenName('');
        setFamilyName('');
        setJobTitle('');
        setIsPreviewVisible(false);
        setIsInvitationSent(false);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setMessage(''); // Clear message on email change
    };

    const handleGivenNameChange = (e) => setGivenName(e.target.value);
    const handleFamilyNameChange = (e) => setFamilyName(e.target.value);
    const handleTitleChange = (e) => setJobTitle(e.target.value);

    // const handleNavigateToReports = () => {
    //     navigate('/membershipreports/management');
    // };

    const handleEmailCheck = async () => {
        if (!email || !givenName || !familyName) {
            setMessage('Please fill in all required fields.');
            setIsError(true);
            return;
        }

        const validateEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        if (!validateEmail(email)) {
            setMessage('Please enter a valid email address.');
            setIsError(true);
            return;
        }

        try {
            // Get email preview
            const previewResponse = await fetch(`${apiUrl}/getEmailPreview?email=${email}&inst_name=${instname}&given_name=${givenName}&family_name=${familyName}&job_title=${jobTitle}`, {
                method: 'GET',
                credentials: 'include'
            });

            if (previewResponse.ok) {
                const previewContent = await previewResponse.text();
                setEmailPreview(previewContent);
                setIsPreviewVisible(true);
                setEmailExists(true); // Assume email exists if preview is successful
                setIsError(false); // Reset error state on success
            } else {
                setMessage('Failed to get email preview. There might be an issue with the server.');
                setEmailExists(false);
                setIsError(true);
            }
        } catch (error) {
            console.error('Error getting email preview:', error);
            setMessage('Error getting email preview');
            setEmailExists(false);
            setIsError(true);
        }
    };

    const handleGrantAccess = async () => {
        if (!email || !givenName || !familyName) {
            setMessage('Please fill in all required fields.');
            setIsError(true);
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/grantAccess?inst_id=${sid}&email=${email}&inst_name=${instname}&given_name=${givenName}&family_name=${familyName}&job_title=${jobTitle}`, {
                method: 'GET',
                credentials: 'include'
            });

            if (response.ok) {
                setMessage('Invitation sent successfully.');
                setIsInvitationSent(true);
                setIsError(false);
            } else {
                setMessage('Failed to send invitation.');
                setIsInvitationSent(false);
                setIsError(true);
            }
        } catch (error) {
            console.error('Error sending invitation:', error);
            setMessage('Error sending invitation.');
            setIsInvitationSent(false);
            setIsError(true);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            setLoadingError(null);
            setLoading(true); // Set loading true here as well

            try {
                // console.log('fetching')
                const response = await fetch(`${apiUrl}/retrieve/${sid}`, {
                    method: 'GET',
                    credentials: 'include'  // cookie
                });
                if (response.status === 403) {
                    console.error('403 Forbidden: Access is denied.');
                    navigate('/membershipreports/report');
                    return;
                }
                const data = await response.json();
                const chartData = data.barplot;
                const tableData = JSON.parse(data.membertable);

                if (chartData !== "") {
                    chartData.forEach((item) => {
                        if (item['report-type'] === "archive-year-count-barplot") {
                            setArchiveBarplot(item.chart);
                            console.log(item.chart);
                        }
                    });
                }

                if (tableData !== "") {
                    const archiveTableItem = tableData.find(item => item['report-type'] === "archive-year-count-table");
                    if (archiveTableItem) {
                        setArchiveTable(archiveTableItem.table);
                    }
                }

                // Process champion tables in worker
                const championTableData = tableData.filter(item =>
                    item['report-type'] === "champion-article-table" ||
                    item['report-type'] === "champion-consortium-article-table"
                );

                if (championTableData.length > 0) {
                    const tableWorker = new Worker(new URL('./tableWorker.js', import.meta.url));
                    tableWorker.postMessage({ tableData: championTableData });

                    tableWorker.onmessage = (event) => {
                        const { championTable, championConsortiumTable, error, tablesProcessed } = event.data;
                        if (error) {
                            setLoadingError(error);
                        } else {
                            if (championTable) setChampionTable(championTable);
                            if (championConsortiumTable) setChampionConsortiumTable(championConsortiumTable);
                        }
                        if (tablesProcessed) {
                            setChampionTablesChecked(true);
                            tableWorker.terminate();
                        }
                    };
                } else {
                    setChampionTablesChecked(true);
                }

            } catch (error) {
                console.log(plotData);
                console.error('Error fetching diagram:', error);
                setLoadingError("The file does not exist");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [apiUrl, sid, navigate, plotData]);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await fetch(`${apiUrl}/getuser`, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (response.ok) {
                    const userData = await response.json();
                    console.log(userData);
                    setCurrentUser(userData);
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchCurrentUser();
    }, [apiUrl]);

    if (Loading) {
        return <Box
            sx={{
                position: 'fixed',   // cover all screen
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // white background
            }}
        >
            <CircularProgress />
        </Box>;
    }

    if (Loadingerror) {
        return <p>Error loading the plot: {Loadingerror}</p>;
    }

    // if(!ArchiveBarplot&&!ArchiveTable&&!ChampionTable&&!ChampionConsortiumTable){
    //     return <p>No available data for now</p>
    // }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button onClick={() => navigate(-1)} text="← Back" />
                <Box sx={{ display: 'flex', gap: 5 }}>
                    {currentUser?.isAdmin ? (
                        <Button onClick={handleOpenModal} text="Invite from arXiv" />
                    ) : (
                        <Button onClick={handleOpenModal} text="Invite Members" />
                    )}
                </Box>
            </Box>

            <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '35%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        maxHeight: '80vh',
                        overflowY: 'auto'
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Invite Members
                    </Typography>
                    {!isPreviewVisible ? (
                        <>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                Invite members in your organization to join this private dashboard. When they accept the invitation, they will be able to view and interact with the same statistics and data. An email will be sent to the address provided.
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                <TextField
                                    label="Email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />

                                <TextField
                                    label="Job Title (Optional)"
                                    value={jobTitle}
                                    onChange={handleTitleChange}
                                    margin="normal"
                                    fullWidth
                                />
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                                <TextField
                                    label="Given Name"
                                    value={givenName}
                                    onChange={handleGivenNameChange}
                                    margin="normal"
                                    required
                                    fullWidth
                                />
                                <TextField
                                    label="Family Name"
                                    value={familyName}
                                    onChange={handleFamilyNameChange}
                                    margin="normal"
                                    required
                                    fullWidth
                                />
                            </Box>
                        </>
                    ) : (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1" gutterBottom >
                                An invitation email will be sent with the following details:
                            </Typography>
                            <Box sx={{ mt: 2, mb: 2 }}>
                                <Typography variant="body1" >
                                    <strong>From:</strong> membership-dashboard@arxiv.org
                                </Typography>
                                <Typography variant="body1" >
                                    <strong>Subject:</strong> A new arXiv membership dashboard was shared with you
                                </Typography>
                                <Typography variant="body1" >
                                    <strong>Message:</strong>
                                </Typography>
                                <Box sx={{
                                    whiteSpace: 'pre-wrap',
                                    mt: 1,
                                    p: 2,
                                    bgcolor: 'grey.100',
                                    borderRadius: 1,
                                    fontSize: '0.875rem',
                                    lineHeight: 1.43,
                                }}>
                                    {emailPreview && JSON.parse(emailPreview).text.replace(/\\n/g, '\n')}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
                        <Button onClick={handleCloseModal} text="Cancel" />
                        <Button
                            onClick={isInvitationSent ? handleCloseModal : (isPreviewVisible ? handleGrantAccess : handleEmailCheck)}
                            text={isInvitationSent ? "Close" : (isPreviewVisible ? "Confirm" : "Send Invitation")}
                        />
                    </Box>
                    {message && (
                        <Typography
                            variant="body2"
                            marginTop={2}
                            textAlign="right"
                            sx={{
                                color: isError ? 'error.main' : 'success.main', // Use red for errors, green for success
                            }}
                        >
                            {message}
                        </Typography>
                    )}

                </Box>
            </Modal>
            <h1>Submission Statistics for {instname}</h1>
            <p>Thank you for being an arXiv member! This information is provided as a benefit of membership for your
                organization and should not be shared publicly. Questions about the data? Please see our <a
                    href="https://info.arxiv.org/about/submission_data_faq.html" target="_blank"
                    rel="noopener noreferrer"> FAQ</a>.</p>
            <BokehPlot data={ArchiveBarplot} title={'Articles by Subject'} plotId={'archive-year-count-barplot'} />

            {/* Archive Table */}
            <DataTable data={ArchiveTable} title={'Article Count by Subject'} target_id={'archive-year-count-table'} key={'archive-year-count-table'} />

            {/* Champion Tables */}
            {!championTablesChecked ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {ChampionTable && <DataTable data={ChampionTable} title={'Champion Article Listing'} target_id={'champion-article-table'} key={'champion-article-table'} />}
                    {ChampionConsortiumTable && <DataTable data={ChampionConsortiumTable} title={'Champion Article Listing'} target_id={'champion-consortium-article-table'} key={'champion-consortium-article-table'} />}
                </>
            )}
            Data provided by <img width="44" style={{ verticalAlign: 'middle' }} src="https://arxiv.org/scopus.png" alt="arXiv logo" />

            <h2 id="member-resources">Member Resources</h2>
            <ul>
                <li><a href="https://info.arxiv.org/about/membership.html">Membership program information</a></li>
                {/*<li><a href="https://info.arxiv.org/about/membership_confirm.html">Renew your membership or update your contact information</a></li>*/}
                {/*<li><a href="https://static.arxiv.org/static/arxiv.marxdown/0.1/about/arXiv-payment-info.pdf">Payment instructions (pdf)</a></li>*/}
                <li><a href="mailto:membership@arxiv.org">Questions? We're happy to help</a></li>
            </ul>
        </div>
    );
}

export default Report;
