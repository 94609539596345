import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {CardActionArea} from "@mui/material";
// import * as Bokeh from '@bokeh/bokehjs';



function MediaCard({institution}) {
    const navigate = useNavigate();
    const handleClick = (event, institutionID,institutionName) => {
        // console.log('sid:', institutionID,institutionName);
        navigate('/membershipreports/report', { state: {institutionID, institutionName} });
    }
    const getFontSize=(name)=>{
        if(name.length > 40){
            return "h6"
        }
        return "h5"
    }
    return (
        <Card sx={{ height: 200, borderRadius: '10px' }}>
            <CardActionArea onClick={(event) => handleClick(event, institution.institution_id, institution.institution_name)} sx={{ height: '100%' }} >
                <CardContent>
                    <Typography gutterBottom variant={getFontSize(institution.institution_name)} component="div">
                        {institution.institution_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {institution.institution_info}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default MediaCard;
