import {useEffect} from "react";

const RedirectLogin = () => {
  useEffect(() => {
    const env = process.env.REACT_APP_ENV;
    let baseUrl, membersUrl;

    if (env === 'LOCAL' || env === 'DEV') {
      baseUrl = 'https://dev.arxiv.org';
      membersUrl = 'https://members.dev.arxiv.org';
    } else {
      // PROD
      baseUrl = 'https://arxiv.org';
      membersUrl = 'https://members.arxiv.org';
    }
    
    const loginUrl = `${baseUrl}/login?next_page=${membersUrl}/membershipreports/selection`;
    
    window.location.href = loginUrl;
  }, []);

  return null;
};

export default RedirectLogin;
